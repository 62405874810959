/**
 * 板块选择器组件事件
 */
var pop = $('.bkselectorpop');
var more = $('.bkselectormore');
more.click(function (event) {
    var thispop = $(this).next();
    if (thispop.is(":visible"))
        thispop.hide();
    else
        thispop.show();
    event.stopPropagation();
});
pop.click(function (event) {
    event.stopPropagation();
});
pop.on('click', '.pop_close', function () {
    $(this).parents('.bkselectorpop').hide();
}).on('click', '.pop_slider li', function () {
    $(this).siblings().removeClass('at');
    $(this).addClass('at');
    $(this).parents('.bkselectorpop').find('.pop-cont').hide().eq($(this).index()).show();
});
$(document).click(function () {
    pop.hide();
});

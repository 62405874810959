module.exports = {
  development: {
    dataurl: function(){
      return '//reportapi.uat.emapd.com/'
    },
    quoteurl: function(){
      return '//push2.eastmoney.com/'
    },
    quotehisurl: function(){
      return '//push2his.eastmoney.com/'
    },    
    dcfmurl: function(){
      return '//dcfm.eastmoney.com/'
    }, 
    anoticeurl: function(){
      return '//np-anotice-stock.eastmoney.com/'
    },
    cnoticeurl: function(){
      return '//np-cnotice-stock.eastmoney.com/'
    },
    datacenter: function(){
      return '//datacenter-web.eastmoney.com/'
    },
    soapi: function(){
      return '//searchapi.eastmoney.com/'
    }, 
    cmsdataapi: function(){
      return '//cmsdataapi.eastmoney.com/'
    },
    newsinfo: function(){
      return '//newsinfo.eastmoney.com/'
    },
    url: function(){
      return ''
    }
  },
  zptest: {
    dataurl: function(){
      return '//reportapi.uat.emapd.com/'
    },
    quoteurl: function(){
      return '//push2test.eastmoney.com/'
    },
    quotehisurl: function(){
      return '//push2his.eastmoney.com/'
    }, 
    dcfmurl: function(){
      return '//dcfm.eastmoney.com/'
    },
    anoticeurl: function(){
      return '//np-anotice-stock-test.eastmoney.com/'
    },
    cnoticeurl: function(){
      return '//np-cnotice-stock-test.emapd.com/'
    },
    datacenter: function(){
      return '//testdatacenter.eastmoney.com/'
    },
    soapi: function(){
      return '//searchapi.eastmoney.com/'
    },    
    cmsdataapi: function(){
      return '//cmsdataapi.eastmoney.com/'
    },
    newsinfo: function(){
      return '//newsinfo.eastmoney.com/'
    },
    url: function(){
      return ''
    }
  },
  gray: {
    dataurl: function(){
      return '//reportapi.eastmoney.com/'
    },
    quoteurl: function(){
      return '//push2.eastmoney.com/'
    },
    quotehisurl: function(){
      return '//push2his.eastmoney.com/'
    },      
    dcfmurl: function(){
      return '//dcfm.eastmoney.com/'
    },
    anoticeurl: function(){
      return '//np-anotice-stock.eastmoney.com/'
    },
    cnoticeurl: function(){
      return '//np-cnotice-stock.eastmoney.com/'
    },
    datacenter: function(){
      return '//graydatacenter.eastmoney.com/'
    },
    soapi: function(){
      return '//searchapi.eastmoney.com/'
    },      
    cmsdataapi: function(){
      return '//cmsdataapi.eastmoney.com/'
    },
    newsinfo: function(){
      return '//newsinfo.eastmoney.com/'
    },
    url: function(){
      return ''
    }
  },
  production: {
    dataurl: function(){
      return '//reportapi.eastmoney.com/'
    },
    quoteurl: function(){
      return '//push2.eastmoney.com/'
    },
    quotehisurl: function(){
      return '//push2his.eastmoney.com/'
    },      
    dcfmurl: function(){
      return '//dcfm.eastmoney.com/'
    },
    anoticeurl: function(){
      return '//np-anotice-stock.eastmoney.com/'
    },
    cnoticeurl: function(){
      return '//np-cnotice-stock.eastmoney.com/'
    },
    datacenter: function(){
      return '//datacenter-web.eastmoney.com/'
    },
    soapi: function(){
      return '//searchapi.eastmoney.com/'
    },      
    cmsdataapi: function(){
      return '//cmsdataapi.eastmoney.com/'
    },
    newsinfo: function(){
      return '//newsinfo.eastmoney.com/'
    },
    url: function(){
      return ''
    }
  },
  getParam: function(name){
    var urlpara = location.search;
    var par = {};
    if (urlpara != "") {
      urlpara = urlpara.substring(1, urlpara.length);
      var para = urlpara.split("&");
      var parname;
      var parvalue;
      for (var i = 0; i < para.length; i++) {
        parname = para[i].substring(0, para[i].indexOf("="));
        parvalue = para[i].substring(para[i].indexOf("=") + 1, para[i].length);
        par[parname] = parvalue;
      }
    }
    if(typeof (par[name]) != "undefined"){
      return par[name];
    }
    else{
      return null;
    }
  },
  getWebPath: function (name) {
    var env=(window.service&&service.ENV)||this.getParam('env');
    if (env&&this[env]) {
      return this[env][name]()
    }
    return this.production[name]()
  }
}

